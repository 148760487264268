/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, change you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.


//
// Bring in Bootstrap
//

// Option 1
//
// Import all of Bootstrap's CSS

// @import "bootstrap/scss/bootstrap";

// Option 2
//
// Import just the styles you need. Note that some stylesheets are required no matter what.

//$primary: #2952cc;
//$secondary: #7d91cd;
// $light: #ecf0f1;
//$success: #13ce66;

$primary: #e81e25;
//$secondary: #ffcf0b;
//$light: #bfbab4;
//$dark: #211e1d;
//$success: #00c853;
//$danger: #f44336;
//$warning: #ffca28;


// $gray-dark: #615c5c;
// $dark: #4d475c;

// $theme-colors: (
//   "blue": #0a20e8,
//   "blue-light": #bac0f8
// );

// $theme-colors: (
//   "tertiary": #017369
// );

//$theme-colors: (
//  "blue-light": #f2f5ff,
//  "blue-dark": #014
//);

// $border-radius:               .1rem;
// $border-radius-lg:            .15rem;
// $border-radius-sm:            .05rem;

$border-radius:               .15rem;
$border-radius-lg:            .25rem;
$border-radius-sm:            .1rem;

@import "bootstrap/scss/functions"; // Required
@import "bootstrap/scss/variables"; // Required
@import "bootstrap/scss/mixins"; // Required

//
// Override Bootstrap here
//

$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3),
  6: ($spacer * 3.5),
  7: ($spacer * 4),
  8: ($spacer * 4.5),
  9: ($spacer * 5),
  10: ($spacer * 5.5),
  11: ($spacer * 6)
);

// Toggle global options
$enable-responsive-font-sizes: true;
$enable-rounded:               true;

// Customize some defaults
$body-color: #272727;
//$body-color: #000;
$body-bg: #fff;

//$link-hover-color: #b61032;

// $font-weight-light: 200;
// $font-weight-normal: 300;
// $font-weight-bold: 600;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);

//$font-family-base: "Archivo", sans-serif;

// $font-weight-base: 100;
// $headings-font-weight:        100;
// $font-weight-light:           100;
// $font-weight-normal:          300;
// $font-weight-bold:            500;

$font-size-base: 1.0625rem;
$font-size-base: 1.15rem;
$line-height-base: 1.5;

// $h1-font-size:                $font-size-base * 2;
// $h2-font-size:                1.688rem;
// $h3-font-size:                $font-size-base * 1.5;
// $h4-font-size:                $font-size-base * 1.25;
// $h5-font-size:                $font-size-base * 1.15;

// $display1-size:               5.5rem;
// $display2-size:               5rem;
// $display3-size:               4.188rem;
// $display4-size:               2.75rem;

// $border-radius:               .5rem;
// $border-radius-lg:            .75rem;
// $border-radius-sm:            .35rem;

@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot"; // Required
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/code";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/input-group"; // Requires forms
@import "bootstrap/scss/custom-forms";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar"; // Requires nav
@import "bootstrap/scss/card";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/jumbotron";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/media";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal"; // Requires transitions
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/print";


//
// HC Off Canvas Import
//

// Core variables
//$hc-offcanvas-nav-transition-duration: .4s !default;
//$hc-offcanvas-nav-transition-timing-function: ease !default;
@import "hc-offcanvas-nav/src/scss/core";
$hc-offcanvas-nav-trigger-color:          $primary;
@import "hc-offcanvas-nav/src/scss/toggle";
// Theme Carbon variables
$hc-offcanvas-nav-background-color:       $primary;
$hc-offcanvas-nav-text-color:           #fff;
$hc-offcanvas-nav-text-size:              20px !default;
@import "hc-offcanvas-nav/src/scss/theme-default";

// HC Off Canvas Custom styles
.hc-offcanvas-nav {
  // font-family: "Rubik", sans-serif;
  .nav-close-button {
    span {
      &::before {
        box-sizing: content-box;
      }
      &::after {
        box-sizing: content-box;
      }
    }
  }
  .nav-item-link {
    color: #fff !important; /* stylelint-disable-line declaration-no-important */
  }
}
.main-nav-toggle {
  position: relative;
  top: auto;
  display: none;
}
.hc-nav-trigger.toggle-open {
  span {
    background: $primary;
    transform: none;
    &::before {
      transform: none;
    }
    &::after {
      transform: none;
    }
  }
  &::before {
    transform: none;
  }
  &::after {
    transform: none;
  }
}
.hc-nav-trigger {
  span {
    height: 2px;
    transition: none;
    transform: none;
    transform-origin: 0;
    &::before {
      height: 2px;
      transition: none;
    }
    &::after {
      height: 2px;
      transition: none;
    }
  }
}


//
// Custom styles
//

// .title {
//   @include font-size(4rem);
// }

// .title-1 {
//   @include font-size(4rem !important);
// }
